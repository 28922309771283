import Vue from 'vue';
import Vuex from 'vuex';

import user from "./modules/user";
import login from "./modules/login";
import webSocketModule from "./modules/WebSocket";
import xiaqu from "./modules/xiaqu";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // 挂载子仓库
    user,
    login,
    webSocketModule,
    xiaqu,
  },
  state: {
    userUrl: "",//用户登录
    expertUrl: "",//专家评标系统
    expertLoginUrl: "",//专家登录
    picketageExpertUrl: "",//定标系统
    projectInfoServe: {},//项目信息
    oldUrl: "https://www.bafanghaoda.com/business/register",//用户注册
    websiteUrl: "https://hb.bafanghaoda.com/dzbh/website",//保函办理
    ggzUrl: "https://hebcaonline.hebca.com/Hebca/certbussconduct/toPage.action?projectType=bfhztb&toPage=main_ggzy_2&onlineType=4",//CA办理
    scrollbarLoading: false,//滚动条加载状态
    isPhone: null,//是否手机端
  },
  mutations: {
    setProjectInfoServe(state, value) {
      state.projectInfoServe = value;
      state.userUrl = value.homePagePath + '/business/login';
      state.expertUrl = value.evaluationPath + '/evaluationPackageIndex';
      state.picketageExpertUrl = value.homePagePath + '/picketage/evaluationPackageIndex';
      state.expertLoginUrl = value.homePagePath + '/business/loginExpert';
    },
  },
  actions: {
    setProjectInfoServe({ commit }, value) {
      commit('setProjectInfoServe', value)
    },
  }
});